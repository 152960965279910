<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? stock.fiscal_year_bn : stock.fiscal_year }}</b-td>
                              <b-th>{{ $t('dae_subsidy.circular_ty') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? stock.circular_type_bn : stock.circular_type }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? stock.circular_name_bn : stock.circular_name }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                          <div class="table-responsive">
                            <b-table-simple striped bordered small>
                              <b-thead>
                                <b-tr>
                                  <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                  <b-th class="text-center">{{ $t('dae_subsidy.items_name') }}</b-th>
                                  <b-th class="text-center">{{ $t('dae_subsidy.quantity') }}</b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <template v-if="details.length">
                                <b-tr v-for="(detail, index) in details" :key="index">
                                  <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                  <b-td class="text-center">{{ localeLang === 'en' ? detail.item_name : detail.item_name_bn }}</b-td>
                                  <b-td class="text-center">{{ $n(detail.total_quantiy, { useGrouping: false }) }} {{ detail.type !== 1 ? $t('dae_subsidy.kg') : $t('dae_subsidy.pcs') }}</b-td>
                                </b-tr>
                                </template>
                                <template v-else>
                                  <b-tr>
                                    <b-td colspan="3" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                  </b-tr>
                                </template>
                              </b-tbody>
                            </b-table-simple>
                          </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyStockDetails } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.stock = this.item
    this.getStockDetails()
  },
  data () {
    return {
      loading: false,
      stock: {},
      details: []
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    }
  },
  methods: {
    async getStockDetails () {
      this.loading = true
      const params = { circular_id: this.item.circular_id, type: this.item.type }
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyStockDetails, params)
      .then(response => {
        if (response.success) {
          this.details = this.getRelationalData(response.data)
        } else {
          this.details = []
        }
        this.loading = false
      })
    },
    getRelationalData (data) {
      return data.map(item => {
        let itemObj = ''
        if (item.type === 1) {
          const tmpItem = this.$store.state.incentiveGrant.commonObj.instrumentList.find(tmp => tmp.value === item.item_id)
          itemObj = {
            item_name: tmpItem !== undefined ? tmpItem.text_en : '',
            item_name_bn: tmpItem !== undefined ? tmpItem.text_bn : ''
          }
        } else if (item.type === 2) {
          const tmpItem = this.$store.state.incentiveGrant.commonObj.cropList.find(tmp => tmp.value === item.item_id)
          itemObj = {
            item_name: tmpItem !== undefined ? tmpItem.text_en : '',
            item_name_bn: tmpItem !== undefined ? tmpItem.text_bn : ''
          }
        } else if (item.type === 3) {
          const tmpItem = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(tmp => tmp.value === item.item_id)
          itemObj = {
            item_name: tmpItem !== undefined ? tmpItem.text_en : '',
            item_name_bn: tmpItem !== undefined ? tmpItem.text_bn : ''
          }
        }
        return Object.assign(item, itemObj)
      })
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
