<template>
    <b-container fluid>
      <b-overlay :show="loading">
          <iq-card>
              <template v-slot:body>
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                          <div class="row">
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                              <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="division_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.division_id"
                                      :options="divisionList"
                                      id="division_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="authLocation.division_id != 0"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                              <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="district_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.district_id"
                                      :options="districtList"
                                      id="district_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="authLocation.district_id != 0"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                              <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="upazilla_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.upazilla_id"
                                      :options="upazilaList"
                                      id="upazilla_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="authLocation.upazilla_id != 0"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Circular Type" vid="subsidy_type">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="subsidy_type"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                            {{$t('dae_subsidy.circular_type')}}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="formDataDetails.subsidy_type"
                                            :options="subsidyTypeList"
                                            id="subsidy_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Circular" vid="circular_id">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="circular_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('dae_grant_allocation_distribution.circular')}}
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formDataDetails.circular_id"
                                          :options="circularList"
                                          id="circular_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>

                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="fiscal_year_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('dae_grant_allocation_distribution.fiscal_year')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formDataDetails.fiscal_year_id"
                                          :options="fiscalList"
                                          id="fiscal_year_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <!-- -------------------------- -->
                              <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="season_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('dae_grant_allocation_distribution.season')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formData.season_id"
                                          :options="seasonSetupList"
                                          id="season_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                               </b-col> -->
                              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="project" vid="project_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="project_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('dae_grant_allocation_distribution.project')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formDataDetails.project_id"
                                          :options="projectNameList"
                                          id="project_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                               </b-col>
                              <!-- -------------------------- -->
                          </div>
                          <template>
                              <div style="font-size:15px; background-color: #005B5B;">
                              <h5 class="text-white text-center mb-3">
                                  {{ $t('dae_subsidy.stock_list') }} {{ $t('globalTrans.details') }}
                              </h5>
                              </div>
                          </template>
                              <div class="row">
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Stock Date" vid="purchase_order_date" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="purchase_order_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('dae_subsidy.stock_date') }} <span class="text-danger">*</span>
                                      </template>
<!--                                      <b-form-input-->
<!--                                          class="purchaseOrderDate"-->
<!--                                          id="purchase_order_date"-->
<!--                                          v-model="formData.purchase_order_date"-->
<!--                                          :state="errors[0] ? false : (valid ? true : null)"-->
<!--                                          :placeholder="$t('dae_subsidy.stock_date')"-->
<!--                                      ></b-form-input>-->
                                      <flat-pickr class="form-control"
                                                  v-model="formData.purchase_order_date"
                                                  :placeholder="$t('globalTrans.select_date')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                      ></flat-pickr>
                                      <div class="invalid-feedback d-block" v-if="errors.length">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Type" vid="stock_type">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="stock_type"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('dae_subsidy.stock_type')}}
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formDataDetails.stock_type"
                                          :options="stockTypeList"
                                          id="subsidy_type"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Instrument/Material" vid="item_id" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="item_id"
                                      >
                                      <template v-slot:label>
                                          <span v-if="instrumentSel">{{$t('dae_subsidy.instrument_name')}}</span> <span class="text-danger">*</span>
                                          <span v-if="cropsSel">{{$t('dae_subsidy.crops_name')}}</span>
                                          <span v-if="meteriatSel">{{$t('dae_subsidy.material_name')}}</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="formDataDetails.item_id"
                                          :options="itemList"
                                          id="item_id"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                  <ValidationProvider name="Quantity" vid="quantity" >
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="quantity"
                                      >
                                      <template v-slot:label>
                                          {{$t('dae_subsidy.quantity')}}
                                      </template>
                                          <b-form-input
                                          id="quantity"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          v-model="formDataDetails.quantity"
                                          ></b-form-input>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </div>
                          <!-- -----------------Add More Start------------------- -->
                               <div class="my-3 text-right">
                              <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                              ><i class="fas fa-plus-circle m-0"></i> {{$t('dae_subsidy.add')}}</button>
                              </div>
                             <b-table-simple striped bordered small class="mt-2">
                                  <b-thead>
                                  <tr class="text-center">
                                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th>{{ $t('dae_grant_allocation_distribution.circular') }}</b-th>
                                      <b-th>{{ $t('dae_subsidy.stock_type') }}</b-th>
                                      <b-th>{{ $t('dae_subsidy.items_name') }}</b-th>
                                      <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                                      <b-th>{{ $t('globalTrans.action') }}</b-th>
                                  </tr>
                                  </b-thead>
                                  <b-tbody>
                                  <template v-if="formData.stockDetailsArr && formData.stockDetailsArr.length">
                                      <b-tr v-for="(item, index) in formData.stockDetailsArr" :key="index">
                                        <b-td  class="text-center">{{ index+1 }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.circular_name : item.circular_name_bn }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.stock_type_name : item.stock_type_name_bn }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.item_name : item.item_name_bn }}</b-td>
                                        <b-td class="text-center"><b-form-input style="width: 50px;" v-model="item.quantity"></b-form-input></b-td>
                                        <b-td class="text-center">
                                        <!-- <button style="margin-right: 5px;" @click="stockListEdit(index)" class="btn btn-sm btn-success" type="button"><i class="fas fa-edit m-0"></i></button> -->
                                        <button @click="remove(index)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i></button>
                                        </b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                      <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                                  </b-tbody>
                              </b-table-simple>
                          <!-- -----------------Add More End--------------------- -->
                          <div class="row">
                          <div class="col-sm-6 col-md-6">
                              <div class="row">
                                  <div class="col-sm-3"></div>
                                  <div class="col text-right">
                                      <!-- <b-button type="submit" variant="primary" class="mr-2" :disabled='isDisabled'>{{ saveBtnName }}</b-button> -->
                                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                      &nbsp;
                                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                  </div>
                              </div>
                          </div>
                          </div>
                      </b-form>
                  </ValidationObserver>
              </template>
          </iq-card>
       </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyStockNewStore, subsidyStockUpdate, circularDataApi, subsidyStockCircularInstrument, subsidyStockCircularCrops } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
            tmp.demand_collection_details.map(item => {
                this.formData.total_land = item.total_land
                this.formData.total_damage_land = item.total_damage_land
                this.formData.partial_damage_converts_complete_loss = item.partial_damage_converts_complete_loss
                this.formData.affected_land = item.affected_land
                this.formData.complete_loss = item.complete_loss
                this.formData.partial_loss_rate = item.partial_loss_rate
                this.formData.damaged_land = item.damaged_land
                this.formData.partially_damaged_land = item.partially_damaged_land
                this.formData.loss_rate_on_total_crop_land = item.loss_rate_on_total_crop_land
                this.formData.loss_on_production = item.loss_on_production
            })
        }
      this.setAuthLocationInfo()
    },
    data () {
        return {
            loading: false,
            instrumentSel: false,
            cropsSel: false,
            meteriatSel: true,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                purchase_order_date: '',
                stockDetailsArr: []
            },
            formDataDetails: {
              subsidy_type: 0,
              circular_id: 0,
              fiscal_year_id: 0,
              season_id: 0,
              project_id: 0,
              stock_type: 0,
              item_id: 0,
              quantity: 0
            },
            districtList: [],
            upazilaList: [],
            circularList: [],
            itemList: [],
            editKeyNum: '',
            authLocation: {
              division_id: 0,
              district_id: 0,
              upazilla_id: 0
            }
        }
    },
    watch: {
        'formData.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
        },
        'formData.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        },
        'formDataDetails.subsidy_type': function (newVal, oldVal) {
        // this.loading = true
          this.itemList = []
          this.formData.circular_id = 0
          this.circularList = this.getCircularList(newVal)
        },
        'formDataDetails.circular_id': function (newValue) {
          this.itemList = []
          if (newValue) {
            this.getCircularDetails(newValue)
          }
        },
        'formDataDetails.stock_type': function (newValue) {
          this.itemList = []
          if (newValue) {
            this.getStockTypeItem(newValue)
          }
        }
    },
    mounted () {
        core.index()
        flatpickr('.purchaseOrderDate', {})
    },
    computed: {
        isDisabled: function () {
            return this.formData.stockDetailsArr.length === 0
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        seasonSetupList: function () {
            const seasonData = this.$store.state.incentiveGrant.commonObj.seasonSetupList
            return seasonData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        projectNameList: function () {
            const projectNameData = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
            return projectNameData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        subsidyTypeList: function () {
            const list = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন' }
            ]
           return list
        },
        stockTypeList: function () {
            const list = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Seed' : 'বীজ' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Material' : 'উপাদান' }
            ]
           return list
        },
        // circularList: function () {
        //     const circularData = this.$store.state.incentiveGrant.commonObj.subsidyCircularList.filter(item => item.status === 1)
        //     return circularData.map(item => {
        //         if (this.$i18n.locale === 'bn') {
        //             return { value: item.value, text: item.text_bn }
        //         } else {
        //             return { value: item.value, text: item.text_en }
        //         }
        //     })
        // },
        currentLocale: function () {
            return this.$i18n.locale
        },
        divisionList: function () {
          let divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
          if (this.authLocation.division_id) {
            divisionList = divisionList.filter(item => item.value === this.authLocation.division_id)
          }
          return divisionList
        },
        instrumentList: function () {
            const instrumentData = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
            return instrumentData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async getCircularDetails (circularId) {
           this.loading = true
           const params = Object.assign({}, { circular_id: circularId })
            await RestApi.getData(incentiveGrantServiceBaseUrl, circularDataApi, params).then(response => {
                if (response.success) {
                    this.formDataDetails.fiscal_year_id = response.data.fiscal_year_id
                    this.formDataDetails.season_id = response.data.season_id
                    this.formDataDetails.project_id = response.data.project_id
                    // this.formData.stockDetailsArr = []
                }
                this.loading = false
            })
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${subsidyStockUpdate}/${this.id}`, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subsidyStockNewStore, this.formData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        addItem () {
          if (this.formDataDetails.subsidy_type && this.formDataDetails.circular_id && this.formDataDetails.stock_type && this.formDataDetails.item_id && this.formDataDetails.quantity > 0) {
              const stCircular = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.value === this.formDataDetails.circular_id)
              const stStockType = this.stockTypeList.find(item => item.value === this.formDataDetails.stock_type)
              const stItem = this.itemList.find(item => item.value === this.formDataDetails.item_id)
              //  -------------Start--------------
              const existData = this.formData.stockDetailsArr.find(item => item.circular_id === this.formDataDetails.circular_id && item.subsidy_type === this.formDataDetails.subsidy_type && item.item_id === this.formDataDetails.item_id)
              if (typeof existData === 'undefined') {
                const obj = {
                  circular_id: this.formDataDetails.circular_id,
                  circular_name: stCircular[0]?.text_en,
                  circular_name_bn: stCircular[0]?.text_bn,
                  subsidy_type: this.formDataDetails.subsidy_type,
                  stock_type: this.formDataDetails.stock_type,
                  stock_type_name: stStockType?.text,
                  stock_type_name_bn: stStockType?.text,
                  item_id: this.formDataDetails.item_id,
                  item_name: stItem?.text,
                  item_name_bn: stItem?.text,
                  quantity: this.formDataDetails.quantity
                }
                this.formData.stockDetailsArr.push(obj)
              } else {
                  this.$toast.error({
                      title: 'Error',
                      message: this.$i18n.locale === 'bn' ? 'তথ্য ইতিমধ্যেই বিদ্যমান!' : 'Data already exists!'
                  })
              }
              //  -------------End----------------
          }

          this.formDataDetails = {
            subsidy_type: 0,
            circular_id: 0,
            fiscal_year_id: 0,
            season_id: 0,
            project_id: 0,
            stock_type: 0,
            item_id: 0,
            quantity: 0
          }
          this.$refs.form.reset()
        },
        remove (key) {
          this.formData.stockDetailsArr.splice(key, 1)
        },
        stockListEdit (key) {
        //   this.formData.stockDetailsArr[key]
        },
        getDistrictList (divisionId) {
          let list = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
          if (this.authLocation.district_id) {
            list = list.filter(item => item.value === this.authLocation.district_id)
          }
          if (divisionId) {
              return list.filter(district => district.division_id === divisionId)
          }
          return list
        },
        getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

          if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
          }

          return upazilaList
        },
        getCircularList (subsityType = null) {
            const circularData = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === subsityType)
            return circularData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        // async getStockTypeItem (circularId = this.formData.circular_id, subsityType = this.formData.subsidy_type) {
        async getStockTypeItem (stockType) {
            if (stockType === 1) {
            this.itemList = []
            this.instrumentSel = true
            this.cropsSel = false
            this.meteriatSel = false
            this.loading = true
            //    const params = Object.assign({}, { circular_id: circularId })
            const params = Object.assign({}, { circular_id: this.formDataDetails.circular_id })
            await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyStockCircularInstrument, params).then(response => {
            if (response.success) {
                this.itemList = response.data.map(item => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: item.value, text: item.text_bn }
                    } else {
                        return { value: item.value, text: item.text_en }
                    }
                })
            }
            this.loading = false
            })
            } else if (stockType === 2) {
                this.itemList = []
                this.instrumentSel = false
                this.cropsSel = true
                this.meteriatSel = false
                this.loading = true
                const params = Object.assign({}, { circular_id: this.formDataDetails.circular_id })
                await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyStockCircularCrops, params).then(response => {
                if (response.success) {
                    this.itemList = response.data.map(item => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: item.value, text: item.text_bn }
                        } else {
                            return { value: item.value, text: item.text_en }
                        }
                    })
                }
                this.loading = false
                })
            } else if (stockType === 3) {
            //   return this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
            this.itemList = []
            this.instrumentSel = false
            this.cropsSel = false
            this.meteriatSel = true
            const meterialData = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
            this.itemList = meterialData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
            })
            }
        },
      setAuthLocationInfo () {
        let location = {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        }
        const authUser = this.$store.state.Auth.authUser
        if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
          const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
          if (typeof office !== 'undefined') {
            location = {
              division_id: office.division_id,
              district_id: office.district_id,
              upazilla_id: office.upazilla_id
            }
          }
        }
        this.formData = Object.assign({}, this.formData, location)
        this.authLocation = Object.assign({}, this.authLocation, location)
      }
    }
}
</script>
