
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.stock') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_subsidy.circular_type')"
                      label-for="subsidy_type"
                      >
                      <b-form-select
                          plain
                          v-model="search.subsidy_type"
                          :options="circularTypeList"
                          id="subsidy_type"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                 <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_id"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_name')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_id"
                            :options="circularList"
                            id="circular_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_grant_allocation_distribution.project')"
                      label-for="project_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.project_id"
                          :options="projectNameList"
                          id="project_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.division')"
                      label-for="division_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                          :disabled="authLocation.division_id != 0"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>

               <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('org_pro_district.district')"
                      label-for="district_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          :disabled="authLocation.district_id != 0"
                      >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                      </b-form-group>
                  </b-col>

                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('org_pro_upazilla.upazilla')"
                      label-for="upazilla_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.upazilla_id"
                          :options="upazilaList"
                          id="upazilla_id"
                          :disabled="authLocation.upazilla_id != 0"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                      </b-form-group>
                  </b-col>
                   <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="stock_date_from"
                            >
                                <template v-slot:label>
                                {{ $t('dae_subsidy.stock_date_from') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                class="purchaseOrderDateFrom"
                                id="purchase_order_date_from"
                                v-model="search.purchase_order_date_from"
                                :placeholder="$t('dae_subsidy.stock_date_from')"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                       <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="stock_date_to"
                            >
                                <template v-slot:label>
                                {{ $t('dae_subsidy.stock_date_to') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                class="purchaseOrderDateTo"
                                id="purchase_order_date_to"
                                v-model="search.purchase_order_date_to"
                                :placeholder="$t('dae_subsidy.stock_date_to')"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.stock_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                          <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <!-- <b-button class="mr-1" title="Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                          <a href="javascript:" class="btn_table_action table_action_view" title="Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('dae_subsidy.stock') + ' ' + $t('globalTrans.details') " :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="detailsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyStockNewManagementList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        project_id: 0,
        subsidy_type: 0,
        circular_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      },
      rows: [],
      item: '',
      detailsItemId: '',
      districtList: [],
      upazilaList: [],
      circularList: [],
      purchase_order_date_from: '',
      purchase_order_date_to: '',
      authLocation: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      }
    }
  },
  mounted () {
    flatpickr('.purchaseOrderDateFrom', {})
    flatpickr('.purchaseOrderDateTo', {})
  },
  computed: {
    seasonSetupList: function () {
      const seasonData = this.$store.state.incentiveGrant.commonObj.seasonSetupList
      return seasonData.map(item => {
        if (this.$i18n.locale === 'bn') {
        return { value: item.value, text: item.text_bn }
        } else {
        return { value: item.value, text: item.text_en }
        }
      })
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyearData.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
          }
      })
    },
    projectNameList: function () {
      const projectNameData = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
      return projectNameData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    circularTypeList: function () {
      const subsidyTypeData = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      return subsidyTypeData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    divisionList: function () {
      let divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      if (this.authLocation.division_id) {
        divisionList = divisionList.filter(item => item.value === this.authLocation.division_id)
      }
      return divisionList
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_subsidy.stock') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_subsidy.stock') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_subsidy.circular_ty'), class: 'text-center' },
          { label: this.$t('dae_subsidy.circular'), class: 'text-center' },
          { label: this.$t('dae_subsidy.stock_type'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'circular_type_bn' },
          { key: 'circular_name_bn' },
          { key: 'stock_type_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'circular_type' },
          { key: 'circular_name' },
          { key: 'stock_type' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    stockTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র', text_en: 'Instrument', text_bn: 'যন্ত্র' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Crop' : 'ফসল', text_en: 'Crop', text_bn: 'ফসল' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Material' : 'উপাদান', text_en: 'Material', text_bn: 'উপাদান' }
      ]
      return list
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
      },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      },
    'search.subsidy_type': function (newValue) {
      this.circularList = this.getCircularName(newValue)
    }
  },
  created () {
    this.loadData()
    this.setAuthLocationInfo()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.detailsItemId = item.id
    },
    getCircularName (circularType) {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === circularType)
      return circularList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text }
        }
      })
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyStockNewManagementList, params)
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelatinalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const circularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
        const msCircularList = this.$store.state.incentiveGrant.commonObj.circularList

        const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fy => fy.value === item.fiscal_year_id)
        const circularTypeObj = circularTypeList.find(ct => ct.value === item.circular_type)
        const circularObj = msCircularList.find(cr => cr.value === item.circular_id)
        const stockTypeObj = this.stockTypeList.find(st => st.value === item.type)

        const fiscalYearData = {
          fiscal_year: fiscalYearObj !== undefined ? fiscalYearObj.text_en : '',
          fiscal_year_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
        }
        const cirTypeData = {
          circular_type: circularTypeObj !== undefined ? circularTypeObj.text_en : '',
          circular_type_bn: circularTypeObj !== undefined ? circularTypeObj.text_bn : ''
        }
        const cirData = {
          circular_name: circularObj !== undefined ? circularObj.text_en : '',
          circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
        }
        const stockTypeData = {
          stock_type: stockTypeObj !== undefined ? stockTypeObj.text_en : '',
          stock_type_bn: stockTypeObj !== undefined ? stockTypeObj.text_bn : ''
        }
        return Object.assign({}, item, fiscalYearData, cirTypeData, cirData, stockTypeData)
      })
      return listData
    },
    getDistrictList (DivisionId) {
      let list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === DivisionId)
      if (this.authLocation.district_id) {
        list = list.filter(item => item.value === this.authLocation.district_id)
      }
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    getAddress (divisionId, districtId, upazillaId) {
      const divisionObj = this.$store.state.commonObj.divisionList.find(item => item.value === divisionId)
      const districtObj = this.$store.state.commonObj.districtList.find(item => item.value === districtId)
      const upazillaObj = this.$store.state.commonObj.upazilaList.find(item => item.value === upazillaId)
      if (this.$i18n.locale === 'bn') {
          return divisionObj.text_bn + '-' + districtObj.text_bn + '-' + upazillaObj.text_bn
      } else {
          return divisionObj.text_en + '-' + districtObj.text_en + '-' + upazillaObj.text_en
      }
    },
    getUpazilaList (districtId = null) {
      let upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (this.authLocation.upazilla_id) {
        upazilaList = upazilaList.filter(item => item.value === this.authLocation.upazilla_id)
      }

      if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    setAuthLocationInfo () {
      let location = {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0
      }
      const authUser = this.$store.state.Auth.authUser
      if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
        if (typeof office !== 'undefined') {
          location = {
            division_id: office.division_id,
            district_id: office.district_id,
            upazilla_id: office.upazilla_id
          }
        }
      }
      this.search = Object.assign({}, this.search, location)
      this.authLocation = Object.assign({}, this.authLocation, location)
    }
  }
}
</script>
